import { Coordinate } from "ol/coordinate";
import Point from "ol/geom/Point";
import { Subscription } from "rxjs";
import { BufferOp } from "jsts/org/locationtech/jts/operation/buffer";
import { FilterConfig } from '../datasets/filter';
import { MapConfig } from '../datasets/map-config';
import { MINQUARTER } from 'src/assets/constants/constants';

export function unsubscribe(subscription: Subscription | Subscription[]) {
  const subscriptions = Array.isArray(subscription)
    ? subscription
    : [subscription];
  subscriptions.forEach((s) => {
    if (s && !s.closed) {
      s.unsubscribe();
    }
  });
}

export function localeToLang(locale: string): string {
  return locale.split("-")[0];
}

export function isNullOrUndefined(val) {
  return val === null || val === undefined;
}

export function isNullOrUndefinedOrEmpty(val) {
  return val === null || val === undefined || val === "";
}

export function formatYearQuarter(label: number): string {
  const quarters = ["Q01", "Q02", "Q03", "Q04"];
  const result = Math.floor(label) + quarters[(label % 1) / 0.25];
  return result;
}

export function reFormatYearQuarter(label: string): number {
  const quarters = ['.0', '.25', '.50', '.75'];
  const quarter = label.split('Q0');
  const result = quarter[0] + quarters[Number(quarter[1]) - 1]
  return Number(result);
}

export function getCurrentYearQuarter(): number {
  return (
    new Date().getFullYear() + Math.floor(new Date().getMonth() / 3) * 0.25
  );
}

export function getCurrentYearQuarterPostfix(): string {
  return formatYearQuarter(getCurrentYearQuarter());
}

export function getCurrentYearQuartersPostfixes(): string[] {
  const quarters = [];
  const upVal = 0.25
  let quarter = MINQUARTER;
  while (quarter <= getCurrentYearQuarter()) {
    quarters.push(formatYearQuarter(quarter))
    quarter += upVal;
  }
  return quarters;
}
export function setBackgroudTable(value: string): string {
  if (value == "1 - 10 Mbps") {
    return "#ff1400";
  }
  if (value == "0 - 1 Mbps") {
    return "#ff1400";
  }
  if (value == "10 - 30 Mbps") {
    return "#fb6200";
  }
  if (value == "30 - 50 Mbps") {
    return "#f6b100";
  }
  if (value == "50 - 100 Mbps") {
    return "#f2ff00";
  }
  if (value == "100 - 150 Mbps") {
    return "#232323";
  }
  if (value == "100 - 350 Mbps") {
    return "#232323";
  }
  if (value == "350 - 400 Mbps") {
    return "#32A8E0";
  }
  if (value == "350+ Mbps") {
    return "#32A8E0";
  }
  if (value == "350 - 1000 Mbps") {
    return "#32A8E0";
  }
  if (value == "1000+ Mbps") {
    return "#292A81";
  }
  if (value == "1000 - 2500 Mbps") {
    return "#328c58";
  }
  if (value == "8500 - 10000 Mbps") {
    return "#91ff8a";
  }
}
export function setTextColor(value: string): string {
  if (value === "50 - 100 Mbps") {
    return "black";
  } 
  if (value == "8500 - 10000 Mbps") {
    return "black";
  } else if (!value.includes("Mbps")) {
    return "black";
  } else {
    return "white";
  }
}
export function setBuffer(coordinate: Coordinate, parser: any) {
  const point = new Point(coordinate).transform("EPSG:31370", "EPSG:3857");
  const jstsGeom = parser.read(point);
  const bufferOp = new BufferOp(jstsGeom);
  const buffered = bufferOp.getResultGeometry(250);
  return parser.write(buffered);
}
export function checkCorrectParams(mapfilters: FilterConfig[], mapConfig: MapConfig, filterArr: string[]) {
  const isAllParamsCorrect = filterArr.map((fa) => {
    let isHave = false;
    mapfilters.forEach((fl) => {
      if (fl.choices.some((ch) => ch.value == mapConfig[fa])) {
        isHave = true
      }
    })
    return isHave
  })
  return isAllParamsCorrect;
}
export function checkQueryParameters(mapConfig: MapConfig, filterArr: string[]) {
  let isHave = true
  filterArr.forEach((fa) => {
    if (!mapConfig[fa]) {
      isHave = false
    }
  })
  return isHave
}


import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiFilterValue } from "src/app/datasets/api-filter-value.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GeoserverAPIService {
  constructor(private http: HttpClient) {}
  getMobileDetailedLayerNames(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_mobile_detailed_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getMobileByZoneLayerNames(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_mobile_byzone_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getLandLineCapabilities(): Observable<any> {
    return this.http.get(
      `${environment.geoServer.baseUrl}/${environment.geoServer.dp_landline_overview}/wfs?service=WFS&version=2.0.0&request=GetCapabilities`,
      { responseType: "text" }
    );
  }

  getLandLineDescribeFeatures(typeName: string): Observable<any> {
    return this.http.get<any>(
      `${environment.geoServer.baseUrl}/wfs?service=WFS&version=2.0.0&request=DescribeFeatureType&outputFormat=application/json&typeNames=${typeName}`
    );
  }

  getLandlinePolygonNames(datastoreSuffix: string): Observable<ApiFilterValue> {
    return this.http.get<ApiFilterValue>(
      `${environment.geoServer.baseUrl}/wfs?service=WFS&version=2.0.0&request=DescribeFeatureType&typeNames=`
    );
  }
  getLandlinePointNames(datastoreSuffix: string): Observable<ApiFilterValue> {
    return this.http.get<ApiFilterValue>(
      environment.GeoServer.api.landlineUrl +
        "?datastoreSuffix=" +
        datastoreSuffix
    );
  }
}
